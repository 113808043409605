import { NgClass } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromLayout from '../layout';
import * as fromAssistants from './assistants';
import { ChatsHelper } from './chats.helper';
import { NewConvoClickedEvent } from './chats.page';
import { ConversationTitleFabDesktopResponsive } from './components/conversation-title-fab.desktop.responsive';
import { DocumentViewerSmartComponent } from './components/document-viewer/document-viewer.smart.component';
import { ThreadsListingCollapsedFabsDesktopResponsive } from './components/threads-listing-collapsed-fabs.desktop.responsive';
import { ThreadsListingDesktopResponsive } from './components/threads-listing.desktop.responsive';
import * as fromShared from './shared';
import { ChatActions } from './store/chats.actions';
import { ChatsState, chatsFeature } from './store/chats.reducer';

@Component({
  selector: 'squadbox-chats-desktop',
  imports: [
    RouterModule,
    TranslateModule,
    NgClass,
    ThreadsListingCollapsedFabsDesktopResponsive,
    ThreadsListingDesktopResponsive,
    ConversationTitleFabDesktopResponsive,
    DocumentViewerSmartComponent,
    fromShared.FilenameWithoutExtensionPipe,
  ],
  providers: [ChatsHelper],
  template: `
    @if ( isConversationMode() && !isThreadsListingContainerOpened() && (
    (selectedChat().messages?.length && selectedChat().title) ||
    selectedAssistant() ) ) {
    <squadbox-conversation-title-fab-desktop
      [title]="
        selectedAssistant() ? selectedAssistant()?.name : selectedChat().title
      "
      [enableDropdown]="!selectedAssistant()"
    ></squadbox-conversation-title-fab-desktop>
    }

    <div
      class="grid grid-flow-col h-full overflow-hidden"
      [ngClass]="{
        'grid-cols-[1fr_25rem]': isDocViewerMode(),
        'grid-cols-[min-content_1fr]': isConversationMode()
      }"
    >
      <div class="relative">
        @if (isConversationMode()) {
        <squadbox-threads-listing-collapsed-fabs-desktop
          [isThreadsListingContainerOpened]="isThreadsListingContainerOpened()"
          [assistants]="activeAssistants()"
          (newConvoClicked)="newConvoClicked.emit($event)"
          (toggleThreadsListingContainerClicked)="
            handleToggleThreadsListingContainerClicked()
          "
        ></squadbox-threads-listing-collapsed-fabs-desktop>
        }
        <squadbox-threads-listing-desktop
          [class.!hidden]="isDocViewerMode()"
          [isThreadsListingContainerOpened]="isThreadsListingContainerOpened()"
          [assistants]="activeAssistants()"
          [itemNavigations]="itemNavigations()"
          (newConvoClicked)="newConvoClicked.emit($event)"
          (toggleThreadsListingContainerClicked)="
            handleToggleThreadsListingContainerClicked()
          "
          (chatDeleteClicked)="chatDeleteClicked.emit()"
          (itemClicked)="itemClicked.emit($event)"
        >
        </squadbox-threads-listing-desktop>
        @if (isDocViewerMode()) {
        <squadbox-document-viewer-smart></squadbox-document-viewer-smart>
        }
      </div>
      <div
        class="h-full overflow-hidden"
        [ngClass]="{ 'max-w-[25rem] w-[25rem] px-4': isDocViewerMode() }"
      >
        <div class="h-full w-full overflow-hidden">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styles: [``],
})
export class ChatsDesktopResponsive {
  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly assistantsStore = inject<Store<ChatsState>>(Store);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly chatsHelper = inject(ChatsHelper);

  public readonly translateService = inject(TranslateService);
  public readonly isNewChat = input<boolean>(false);
  public readonly itemNavigations =
    input.required<fromSeriousSystem.ItemNavigation[]>();
  public readonly newConvoClicked = output<NewConvoClickedEvent>();
  public readonly chatDeleteClicked = output();
  public readonly itemClicked = output<fromSeriousSystem.ItemNavigation>();
  public readonly selectedChat = computed(() => {
    const selectedChat = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    );

    return {
      ...selectedChat(),
      title: this.chatsHelper.getChatTitle(selectedChat()?.title),
    };
  });
  public readonly selectedAssistant = this.assistantsStore.selectSignal(
    fromAssistants.assistantsFeature.selectSelectedAssistant
  );
  public readonly isThreadsListingContainerOpened =
    this.chatsStore.selectSignal(
      chatsFeature.selectIsThreadsListingContainerOpened
    );
  public readonly activeAssistants = this.assistantsStore.selectSignal(
    fromAssistants.assistantsFeature.selectActiveAssistants
  );

  public readonly isConversationMode = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsConversationMode
  );
  public readonly isDocViewerMode = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsDocViewerMode
  );

  public handleToggleThreadsListingContainerClicked() {
    if (this.isThreadsListingContainerOpened()) {
      this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
    } else {
      this.chatsStore.dispatch(ChatActions.openThreadsListingContainer());
    }
  }
}
