import { DocumentViewerZoomFactorClickedEvent } from './document-viewer-header.component';
import {
  ZOOM_FACTOR_LIMIT,
  ZOOM_SETTING_STEP,
} from './document-viewer.constants';

export const calculateNewZoomSetting = (
  currentZoomSetting: number,
  direction: DocumentViewerZoomFactorClickedEvent
): number => {
  switch (direction) {
    case 'in':
      return Math.min(
        ZOOM_FACTOR_LIMIT.max * 100,
        currentZoomSetting + ZOOM_SETTING_STEP
      );
    case 'out':
      return Math.max(
        ZOOM_FACTOR_LIMIT.min * 100,
        currentZoomSetting - ZOOM_SETTING_STEP
      );
  }
};

export const zoomFactorToZoomSetting = (zoomFactor: number): number => {
  return zoomFactor * 100;
};

export const resolvePromiseWithTimeout = <T>(
  promise: Promise<T>,
  defaultValue: T,
  timeoutInMs = 3000
): Promise<T> => {
  const timeoutPromise = new Promise<T>((resolve) =>
    setTimeout(() => resolve(defaultValue), timeoutInMs)
  );
  return Promise.race([promise, timeoutPromise]);
};
