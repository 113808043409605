import {
  Component,
  computed,
  effect,
  inject,
  model,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import * as fromLayout from '../../../layout';
import { fromFileUploadHelpers } from '../../assistants';
import * as fromShared from '../../shared';
import { ChatWithMessagesActions } from '../../store/chats.actions';
import { ChatsState, chatsFeature } from '../../store/chats.reducer';
import {
  DocumentViewerContentComponent,
  DocumentViewerZoomSetting,
} from './document-viewer-content.component';
import {
  DocumentViewerHeaderComponent,
  DocumentViewerZoomFactorClickedEvent,
  DocumentViewerZoomFactorLimitReached,
} from './document-viewer-header.component';
import {
  ZOOM_FACTOR_LIMIT,
  ZOOM_SETTING_DEFAULT,
} from './document-viewer.constants';
import {
  calculateNewZoomSetting,
  zoomFactorToZoomSetting,
} from './document-viewer.helper';

@Component({
  selector: 'squadbox-document-viewer-smart',
  imports: [
    DocumentViewerHeaderComponent,
    DocumentViewerContentComponent,
    fromShared.FilenameWithoutExtensionPipe,
  ],
  template: `
    <div
      class="hide-scrollbar w-full h-screen overflow-y-none opacity-100 border-r border-neutral-200"
    >
      @if (selectedDocumentChunksForSelectedDocument(); as documentWithChunk) {
      <squadbox-document-viewer-header
        [title]="documentWithChunk.document.filename | filenameWithoutExtension"
        [fileType]="
          fromFileUploadHelpers.getFileType(
            documentWithChunk.document.extension
          )
        "
        [zoomFactorLimitReached]="zoomFactorLimitReached()"
        [isMobile]="isMobile()"
        (zoomClicked)="handleDocumentViewerHeaderZoomClicked($event)"
        (closeClicked)="handleDocumentViewerHeaderCloseClicked()"
      ></squadbox-document-viewer-header>
      <squadbox-document-viewer-content
        [src]="document().presignedUrl.url"
        [relevantPages]="relevantPages()"
        [relevantTexts]="relevantTexts()"
        [(zoomSetting)]="zoomSetting"
        [(zoomFactor)]="zoomFactor"
        [zoomFactorLimits]="zoomFactorLimits()"
        [isMobile]="isMobile()"
      ></squadbox-document-viewer-content>
      }
    </div>
  `,
  styles: [``],
})
export class DocumentViewerSmartComponent {
  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly documentsService = inject(fromGenerated.DocumentsService);

  public readonly fromFileUploadHelpers = fromFileUploadHelpers;
  public readonly zoomSetting =
    model<DocumentViewerZoomSetting>(ZOOM_SETTING_DEFAULT);
  public readonly zoomFactor = model<number>();
  public readonly zoomFactorLimits = signal<{
    min: number;
    max: number;
  }>(ZOOM_FACTOR_LIMIT);
  public readonly zoomFactorLimitReached =
    computed<DocumentViewerZoomFactorLimitReached>(() => {
      const zoomFactor = this.zoomFactor();
      const zoomFactorLimits = this.zoomFactorLimits();

      if (zoomFactor) {
        if (zoomFactor >= zoomFactorLimits.max) {
          return 'in';
        } else if (zoomFactor <= zoomFactorLimits.min) {
          return 'out';
        }
      }

      return false;
    });
  public readonly selectedDocumentChunksForSelectedDocument =
    this.chatsStore.selectSignal(
      chatsFeature.selectSelectedDocumentWithChunksForSelectedDocument
    );
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );
  public readonly document = signal<{
    chatMessageId: number | undefined;
    documentUuid: string;
    presignedUrl: {
      url: string;
      expiresAt: string;
    };
  }>({
    chatMessageId: undefined,
    documentUuid: '',
    presignedUrl: { url: '', expiresAt: '' },
  });
  public readonly relevantPages = computed(() => {
    return (
      this.selectedDocumentChunksForSelectedDocument()?.documentChunks.reduce(
        (acc, chunk) => [...acc, ...chunk.pageNumbers],
        [] as number[]
      ) ?? []
    ).sort((a, b) => a - b);
  });
  public readonly relevantTexts = computed(() => {
    const documentChunks =
      this.selectedDocumentChunksForSelectedDocument()?.documentChunks;
    return documentChunks
      ? documentChunks
          .flatMap((chunk) => chunk.content.split('\n'))
          .filter((line) => line.trim() !== '')
      : [];
  });

  constructor() {
    effect(() => {
      const documentUuid =
        this.selectedDocumentChunksForSelectedDocument()?.document?.uuid;
      const chatMessageId =
        this.selectedDocumentChunksForSelectedDocument()?.documentChunks[0]
          ?.chatMessageId;
      const isDifferentDocument = documentUuid !== this.document().documentUuid;
      const isDifferentChatMessage =
        chatMessageId !== this.document().chatMessageId;

      if (documentUuid && (isDifferentDocument || isDifferentChatMessage)) {
        this.documentsService
          .documentsControllerGeneratePresignedUrl(documentUuid)
          .pipe(
            tap((presignedUrl) => {
              this.document.set({
                chatMessageId,
                documentUuid,
                presignedUrl: {
                  url: presignedUrl.url,
                  expiresAt: presignedUrl.expiresAt,
                },
              });
            })
          )
          .subscribe();
      }
    });
  }

  public handleDocumentViewerHeaderCloseClicked() {
    this.layoutStore.dispatch(
      fromLayout.LayoutActions.setViewMode({
        viewMode: fromLayout.ViewMode.CONVERSATION,
      })
    );

    this.chatsStore.dispatch(
      ChatWithMessagesActions.unselectDocumentInChatMessage()
    );
  }

  public handleDocumentViewerHeaderZoomClicked(
    event: DocumentViewerZoomFactorClickedEvent
  ) {
    const zoomFactor = this.zoomFactor();
    if (zoomFactor) {
      const zoomSetting = zoomFactorToZoomSetting(zoomFactor);
      this.zoomSetting.set(calculateNewZoomSetting(zoomSetting, event));
    }
  }
}
