import { CdkMenu, CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { ChatModalActions } from '../store/chats.actions';
import { ChatsState } from '../store/chats.reducer';

@Component({
  selector: 'squadbox-conversation-title-fab-desktop',
  imports: [
    TranslateModule,
    fromSeriousSystem.DropdownMenuComponent,
    fromSeriousSystem.UseArrowIconDirective,
    CdkMenuTrigger,
    CdkMenu,
  ],
  template: `
    <div
      class="absolute top-2 left-0 right-0 ml-36 flex items-center justify-center"
    >
      <div
        class="
          group
          flex items-center justify-center gap-2
          rounded-full bg-shades-white border border-neutral-200 active:border-neutral-400
          shadow-md
          h-10 pl-4 pr-3 py-2
          w-fit
          z-20
        "
        [class.cursor-pointer]="enableDropdown()"
        (click)="!enableDropdown() ? $event.stopPropagation() : null"
        [cdkMenuTriggerFor]="menu"
      >
        <span
          class="
          typo-p3 font-semibold
          disabled:text-neutral-400 disabled:cursor-not-allowed disabled:active:border-neutral-200"
          >{{ title() }}</span
        >
        @if(enableDropdown()) {
        <svg
          sdUseArrowIcon="chevron-small-down"
          class="
            group-hover:visible group-hover:size-5
            group-[&[aria-expanded='true']]:visible group-[&[aria-expanded='true']]:size-5
            invisible size-0
            transition-all duration-200
            focus:outline-none
          "
        />
        }
        <!-- DROPDOWN MENU -->
        <ng-template #menu>
          <sd-dropdown-menu
            cdkMenu
            [options]="[
              {
                label: translateService.instant(
                  'CHAT.DROPDOWN_MENU.SAVE_ASSISTANT'
                ),
                icon: 'save',
                action: 'save',
                disabled: true
              },
              {
                label: translateService.instant('CHAT.DROPDOWN_MENU.RENAME'),
                icon: 'edit',
                action: 'rename',
                disabled: true
              },
              {
                label: translateService.instant('CHAT.DROPDOWN_MENU.ARCHIVE'),
                icon: 'archive',
                action: 'archive',
                disabled: true
              },
              {
                label: translateService.instant('CHAT.DROPDOWN_MENU.DELETE'),
                icon: 'delete',
                action: 'delete',
                error: true
              }
            ]"
            (triggerAction)="onTriggerAction($event)"
          ></sd-dropdown-menu>
        </ng-template>
      </div>
    </div>
  `,
})
export class ConversationTitleFabDesktopResponsive {
  private readonly chatsStore = inject<Store<ChatsState>>(Store);

  public readonly translateService = inject(TranslateService);
  public readonly title = input.required<string | undefined>();
  public readonly enableDropdown = input<boolean>(true);

  public onTriggerAction(action: string) {
    if (action === 'delete') {
      this.chatsStore.dispatch(ChatModalActions.openDeleteChatModal());
    }
  }
}
