import {
  Component,
  OnInit,
  ViewEncapsulation,
  computed,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromLayout from '../layout';
import * as fromAssistants from './assistants';
import { NEW_CHAT_TITLE_DEFAULT } from './chats.constants';
import { ChatsDesktopResponsive } from './chats.desktop.responsive';
import { ChatsHelper } from './chats.helper';
import { ChatsMobileResponsive } from './chats.mobile.responsive';
import { ChatsSocket } from './chats.socket';
import {
  ChatActions,
  ChatModalActions,
  ChatsActions,
} from './store/chats.actions';
import { ChatsState, chatsFeature } from './store/chats.reducer';

export type NewConvoClickedEvent = { assistantUuid: string } | void;

@Component({
  selector: 'squadbox-chats',
  imports: [
    TranslateModule,
    FormsModule,
    RouterModule,
    ChatsMobileResponsive,
    ChatsDesktopResponsive,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.ItemsNavigationComponent,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.ModalComponent,
    fromSeriousSystem.UseNavigationIconDirective,
  ],
  providers: [ChatsHelper],
  template: `
    @if (isMobile()) {
    <squadbox-chats-mobile
      [itemNavigations]="itemsNavigations()"
      (newConvoClicked)="handleNewConvoClicked($event)"
      (itemClicked)="handleItemClicked($event)"
    >
    </squadbox-chats-mobile>
    } @else {
    <squadbox-chats-desktop
      [itemNavigations]="itemsNavigations()"
      [isNewChat]="isNewChat()"
      (newConvoClicked)="handleNewConvoClicked($event)"
      (itemClicked)="handleItemClicked($event)"
      (chatDeleteClicked)="onDeleteChat()"
    >
    </squadbox-chats-desktop>
    }

    <sd-modal
      [isModalVisible]="isDeleteChatModalOpened()"
      [modalOptions]="{
        title: translateService.instant('CHAT.DELETE_CHAT_MODAL.TITLE'),
        actions: [
          {
            label: translateService.instant(
              'CHAT.DELETE_CHAT_MODAL.CTA_CANCEL'
            ),
            action: 'cancel',
            type: 'cancel',
            disabled: false,
          },
          {
            label: translateService.instant(
              'CHAT.DELETE_CHAT_MODAL.CTA_DELETE'
            ),
            action: 'delete',
            type: 'delete',
            disabled: false,
          }
        ],
        backdropDismiss: true,
      }"
      (triggerAction)="onTriggerAction($event)"
    >
      <div
        class="w-auto tablet-portrait:w-120"
        innerHTML="{{
          'CHAT.DELETE_CHAT_MODAL.DESCRIPTION'
            | translate : { chatName: selectedChatTitle() }
        }}"
      ></div>
    </sd-modal>
  `,
  styles: [
    `
      .main-navigation-item {
        @apply grid grid-cols-[36px_auto] items-center align-middle gap-3;
        @apply items-center overflow-hidden rounded mx-2 px-3;
        @apply cursor-pointer hover:bg-primary-500/5;
        @apply [&>svg]:size-6 [&>svg]:ml-1 [&>div]:mr-2;
        @apply [&>div]:size-8 [&>div>img]:size-6;
        @apply tablet-portrait:grid-cols-[30px_auto] tablet-portrait:[&>div]:size-7 tablet-portrait:[&>div>img]:size-4;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ChatsPage implements OnInit {
  private readonly chatsSocket = inject(ChatsSocket);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly assistantsStore = inject(
    Store<fromAssistants.AssistantsState>
  );
  private readonly router = inject(Router);

  public readonly translateService = inject(TranslateService);
  private readonly chatsHelper = inject(ChatsHelper);
  public readonly selectedChatId = computed(() => {
    const selectedChat = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    )();
    return selectedChat?.id ?? null;
  });
  public readonly selectedChatTitle = computed(() => {
    const title = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    )()?.title;

    return this.chatsHelper.getChatTitle(title);
  });
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );
  public readonly isDeleteChatModalOpened = this.chatsStore.selectSignal(
    chatsFeature.selectIsDeleteChatModalOpened
  );
  public readonly selectedChat = this.chatsStore.selectSignal(
    chatsFeature.selectSelectedChat
  );
  public readonly isNewChat = computed(() => {
    return this.selectedChat()?.messages?.length === 0;
  });
  public readonly itemsNavigations = computed(() => {
    const items = this.chatsStore.selectSignal(
      chatsFeature.selectChatsAsItemNavigation,
      {}
    );

    return items().map((item) => {
      if (!item.title || item.title === NEW_CHAT_TITLE_DEFAULT) {
        return {
          ...item,
          title: this.translateService.instant(
            `NAVIGATION_ITEMS.${NEW_CHAT_TITLE_DEFAULT}`
          ) as string,
        };
      }
      return item;
    });
  });

  ngOnInit(): void {
    const isChatsStoreLoaded = this.chatsStore.selectSignal(
      chatsFeature.selectLoaded
    );

    if (!isChatsStoreLoaded()) {
      this.chatsStore.dispatch(ChatsActions.loadChats());
    }
    this.assistantsStore.dispatch(
      fromAssistants.AssistantsActions.loadAssistants()
    );

    this.chatsSocket.connect((err) => {
      console.error(err);
    });
  }

  public handleItemClicked(item: fromSeriousSystem.ItemNavigation) {
    this.chatsStore.dispatch(ChatActions.selectChat({ id: item.id }));

    if (
      this.router.url.includes('assistants') ||
      this.router.url.includes('conversation/')
    ) {
      void this.router.navigate(['/chats/conversation']);
    }
  }

  public onExploreMyAssistantsClickedOnMobile() {
    this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
  }

  public async handleNewConvoClicked(event: NewConvoClickedEvent) {
    this.chatsStore.dispatch(ChatActions.selectEmptyChat());

    if (event?.assistantUuid) {
      this.chatsStore.dispatch(
        fromAssistants.AssistantActions.selectAssistant({
          uuid: event.assistantUuid,
        })
      );
    } else {
      this.chatsStore.dispatch(
        fromAssistants.AssistantActions.unselectAssistant()
      );
    }
    await this.router.navigate(['/chats/conversation']);
  }

  public onTriggerAction(action: string) {
    const selectedChatId = this.selectedChatId();

    switch (action) {
      case 'delete':
        if (selectedChatId !== null) {
          this.chatsStore.dispatch(
            ChatsActions.deleteChat({ id: selectedChatId })
          );
        }
        this.chatsStore.dispatch(ChatModalActions.closeDeleteChatModal());
        break;
      case 'cancel':
        this.chatsStore.dispatch(ChatModalActions.closeDeleteChatModal());
        break;
    }
  }

  public onDeleteChat() {
    this.chatsStore.dispatch(ChatModalActions.openDeleteChatModal());
  }
}
