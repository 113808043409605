import { Component, effect, inject, input, model, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSeriousSystem from '@serious-system';
import {
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService,
  PDFNotificationService,
  PdfSidebarView,
  PdfThumbnailDrawnEvent,
  RenderedTextLayerHighlights,
} from 'ngx-extended-pdf-viewer';
import * as fromFeatureFlags from '../../../feature-flags';
import { resolvePromiseWithTimeout } from './document-viewer.helper';

export type DocumentViewerZoomSetting = string | number | undefined;
export type DocumentViewerZoomFactor = number | undefined;

@Component({
  selector: 'squadbox-document-viewer-content',
  imports: [NgxExtendedPdfViewerModule, fromSeriousSystem.SpinnerDirective],
  host: { '[class.is-mobile]': 'isMobile()' },
  template: `
    <!-- Spinner -->
    <div
      class="absolute inset-0 z-10 flex items-center justify-center bg-transparency-black-16"
      [class.hidden]="!isLoading()"
    >
      <div sdSpinner size="md"></div>
    </div>
    <!-- PDF Viewer -->
    <ngx-extended-pdf-viewer
      [src]="src()"
      [enableDragAndDrop]="false"
      [showToolbar]="false"
      [sidebarVisible]="!isMobile()"
      [activeSidebarView]="PdfSidebarView.THUMBS"
      [showBorders]="false"
      [customSidebar]="customSidebar"
      [textLayer]="ffDocumentViewerHighlightText()"
      (pdfLoadingStarts)="handlePdfLoadingStarts()"
      (pdfLoaded)="handlePdfLoaded()"
      (currentZoomFactor)="zoomFactor.set($event)"
      [minZoom]="zoomFactorLimits().min"
      [maxZoom]="zoomFactorLimits().max"
      [zoom]="zoomSetting()"
      (zoomChange)="zoomSetting.set($event)"
      [(page)]="page"
    ></ngx-extended-pdf-viewer>
    <!-- PDF Viewer: Sidebar -->
    <ng-template #customSidebar>
      <pdf-sidebar-content
        id="sidebarContainer"
        [customThumbnail]="customThumbnail"
        (thumbnailDrawn)="onThumbnailDrawn($event)"
        [hideSidebarToolbar]="true"
      ></pdf-sidebar-content>
    </ng-template>
    <!-- PDF Viewer: Thumbnail -->
    <ng-template #customThumbnail>
      <a class="pdf-viewer-template">
        <div class="thumbnail" data-page-number="PAGE_NUMBER">
          <!-- PDF Viewer: Thumbnail: Pin (hidden by default) -->
          <div class="pin" hidden>
            <svg>
              <use href="/assets/ui-basics.sprites.svg#quote"></use>
            </svg>
          </div>
          <div class="image-container">
            <img class="thumbnailImage" />
          </div>
          <div class="page-number">PAGE_NUMBER</div>
        </div>
      </a>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply relative w-full h-full;
        @apply typo-h1 bg-neutral-100;
        @apply flex items-center justify-center;

        ::ng-deep {
          ngx-extended-pdf-viewer {
            @apply w-full h-full;

            #mainContainer,
            #viewerContainer {
              @apply bg-neutral-100 !important;
            }

            #thumbnailView,
            #viewerContainer {
              @apply hide-scrollbar !important;
            }

            #sidebarContainer {
              #sidebarContainer,
              #sidebarContent {
                @apply bg-transparent -top-5 !important;
              }
              #thumbnailView {
                @apply pl-6 pr-0 pb-28 !important; // Fix for last thumbnail clipping
                .thumbnail {
                  @apply border-none mb-11 relative !important;
                  @apply shadow;
                  &.selected {
                    img {
                      @apply border-0 border-b-4 border-primary-500 !important;
                    }
                    .page-number {
                      @apply font-semibold !important;
                    }
                  }
                  .pin {
                    @apply absolute -top-[0.5rem] -left-[0.75rem];
                    @apply flex justify-center items-center;
                    @apply size-6 bg-gradient-a-diagonal;
                    @apply rounded-l-full rounded-tr-full rounded-br-[3000px];
                    @apply z-10;
                    @apply shadow-md;
                    svg {
                      @apply w-full h-full text-neutral-100;
                    }
                  }
                  .page-number {
                    @apply w-full mt-4 text-center !important;
                    @apply typo-caption text-neutral-700 !important;
                  }
                }
              }
            }

            .textLayer .customHighlight,
            .textLayer .customHighlight.selected {
              @apply bg-primary-500/[16%];
              opacity: 1;
            }
          }
        }
      }

      :host:not(.is-mobile) ::ng-deep ngx-extended-pdf-viewer #viewerContainer {
        @apply left-40 !important;

        .page {
          @apply first:mt-5;
          .canvasWrapper {
            @apply shadow;
          }
        }
      }
    `,
  ],
})
export class DocumentViewerContentComponent {
  public readonly src = input.required<string>();
  public readonly relevantPages = input.required<number[]>();
  public readonly page = model<number>(1);
  public readonly relevantTexts = input.required<string[]>();
  public readonly zoomSetting = model.required<DocumentViewerZoomSetting>();
  public readonly zoomFactor = model.required<DocumentViewerZoomFactor>();
  public readonly zoomFactorLimits = input.required<{
    min: number;
    max: number;
  }>();
  public readonly isMobile = input<boolean>(false);
  public readonly isLoading = signal(true);
  public readonly PdfSidebarView = PdfSidebarView;
  private readonly ngxExtendedPdfViewerService = inject(
    NgxExtendedPdfViewerService
  );
  private readonly pdfNotificationService = inject(PDFNotificationService);

  private relevantPagesWithMatches: number[] = [];

  private readonly featureFlagsStore =
    inject<Store<fromFeatureFlags.FeatureFlagsState>>(Store);
  private readonly ffDocumentViewerSourcesPin =
    this.featureFlagsStore.selectSignal(
      fromFeatureFlags.featureFlagsFeature.selectFeatureFlag(
        'ff-document-viewer-sources-pin'
      )
    );
  public readonly ffDocumentViewerHighlightText =
    this.featureFlagsStore.selectSignal(
      fromFeatureFlags.featureFlagsFeature.selectFeatureFlag(
        'ff-document-viewer-highlight-text'
      )
    );

  constructor() {
    effect(() => {
      if (this.src()) {
        this.isLoading.set(true);

        if (this.ffDocumentViewerHighlightText()) {
          // Since the find is done on all pages, we can have matches that are on irrelevant pages and we don't want to highlight them.
          this.removeHighlightedTextsOnIrrelevantPages();
        }
      }
    });
  }

  public async handlePdfLoaded() {
    this.scrollToFirstRelevantPage();
    if (this.ffDocumentViewerHighlightText()) {
      await this.findRelevantTexts();
    }
    this.isLoading.set(false);
  }

  public handlePdfLoadingStarts() {
    this.isLoading.set(true);
  }

  /**
   * By default, the pin is hidden on all thumbnails. This method is called when a thumbnail is drawn,
   * and it sets the `hidden` attribute to `false` for the relevant pages.
   */
  public onThumbnailDrawn(thumbnailEvent: PdfThumbnailDrawnEvent): void {
    if (
      this.ffDocumentViewerSourcesPin() &&
      this.relevantPages().includes(thumbnailEvent.pageId)
    ) {
      const pin =
        thumbnailEvent.thumbnail.querySelector<HTMLDivElement>('.pin');
      if (pin) {
        pin.hidden = false;
      }
    }
  }

  private scrollToFirstRelevantPage() {
    if (this.ffDocumentViewerSourcesPin()) {
      const relevantPages = this.relevantPages();
      if (relevantPages.length) {
        this.page.set(relevantPages[0]);
      }
    }
  }

  private async findRelevantTexts() {
    const matchCountPerPagePromises = this.ngxExtendedPdfViewerService.find(
      this.relevantTexts(),
      {
        useSecondaryFindcontroller: true,
        highlightAll: true,
        findMultiple: true,
        // We disable the scroll into view because a match can possibly be found in a page that comes
        // before the relevant pages
        dontScrollIntoView: true,
      }
    );

    if (!matchCountPerPagePromises) {
      return;
    }

    await Promise.all(
      matchCountPerPagePromises.map((matchCountPerPagePromise, pageIndex) =>
        this.processPageMatches(matchCountPerPagePromise, pageIndex)
      )
    );

    // eslint-disable-next-line no-console
    console.log(this.relevantPagesWithMatches);
  }

  private async processPageMatches(
    matchCountPerPagePromise: Promise<number>,
    pageIndex: number
  ): Promise<void> {
    if (this.relevantPages().includes(pageIndex)) {
      // We observed that sometimes, promises were not resolving on large documents, hence why we
      // added a timeout as a workaround.
      // However, the value of `this.relevantPagesWithMatches` will be inaccurate
      // if the promise got timed out. It's okay for now because we're not using it but we
      // will need to find a better solution in SB-1362.
      const matchCount = await resolvePromiseWithTimeout(
        matchCountPerPagePromise,
        0
      );

      if (matchCount > 0) {
        this.relevantPagesWithMatches.push(pageIndex);
      }
    }
  }

  private removeHighlightedTextsOnIrrelevantPages() {
    const pdfViewerApplication =
      this.pdfNotificationService.onPDFJSInitSignal();

    pdfViewerApplication?.eventBus?.on(
      'renderedtextlayerhighlights',
      (event: RenderedTextLayerHighlights) => {
        event.highlights.forEach((highlight) => {
          if (
            !this.relevantPages().includes(event.pageIndex + 1) &&
            highlight.classList.contains('customHighlight')
          ) {
            highlight.classList.remove('customHighlight');
          }
        });
      }
    );
  }
}
